import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { BrandContext } from '../App';
import { LandingLayout } from '../components/LandingLayout';

export default function LogoutPage(props) {
    const { saveAuthToken } = React.useContext(BrandContext);

    const navigate = useNavigate();

    React.useEffect(() => {
        saveAuthToken('');
        navigate("/");
      }, []);

    return (
        <LandingLayout>
            Logged out.
        </LandingLayout>
    );
}
