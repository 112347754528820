import React from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  Alert,
  Paper,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Typography,
  Stack,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { BrandContext } from '../App';
import { getSignupSteps } from '../components/SignupSteps';
import { LandingLayout } from '../components/LandingLayout';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const signupValueRegex = {
    'expert': [
      {
        'first_name': /[A-Z ]+/i,
        'last_name': /[A-Z ]+/i,
        'email': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'roletitle': /[a-z]+/i,
        'career_start': /[0-9]+/,
      },
      {
        'skills': /[A-Z ]+/i,
      },
      {
        "fee_currency": /[A-Z ]+/i,
        "fee_hourly": /^\d*\.?\d+$/
      }
      
    ],

    'company': [{
      'first_name': /^[A-Z ]+$/i,
      'last_name': /^[A-Z ]+$/i,
      'email': /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    }],
    
  };

export default function Signup() {
    const { brand } = React.useContext(BrandContext);
    const [activeStep, setActiveStep] = React.useState(0);
    const [signupType, setSignupType] = React.useState('');
    const [steps, setSteps] = React.useState([]);

    const [signupValues, setSignupValues] = React.useState({});
    const [signupValueErrors, setSignupValueErrors] = React.useState([]);

    const { signupFlowName } = useParams();

    const navigate = useNavigate();

    React.useEffect(() => {
      if(signupFlowName === 'expert') {
        setSignupType('expert');
      }
      if(signupFlowName === 'company') {
        setSignupType('company');
      }
    }, [signupFlowName]);

    React.useEffect(() => {
      document.title = `Create an account on ${brand.name}`;
    }, [brand]);
    
    function getSignupValueErrors() {
      var errors = [];

      if(signupType.length < 1) {
        return [];
      }

      if(!signupValueRegex[signupType][activeStep]) {
        return [];
      }

      for (const [name, regex] of Object.entries(signupValueRegex[signupType][activeStep])) {
        // Add into list of errors if the field isn't filled in or fails the regex test
        if(!Object.keys(signupValues).includes(name) || !regex.test(signupValues[name])) {
          errors.push(name);
        }
      }
      return errors;
    }

    React.useEffect(() => {
      if(signupType.length > 0) {
        setSteps(getSignupSteps(signupType, brand));
      }
      
    },[signupType]);

    React.useEffect(() => {
      const errors = getSignupValueErrors();
      setSignupValueErrors(errors);

      if(!signupType) {
        return;
      }

      // Handle submit
      if(errors.length === 0 && activeStep === steps.length) {
        fetch(`${brand.api_base_url}/brand/${brand.id}/signup`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({...signupValues, ['type']: signupType})
          })
              .then((response) => response.json())
              .then((data) => {
                  console.log('success');
                  navigate('/info/signup-completed');
              }).then()
              .catch((err) => {
                  console.log(err.message);
              });
      }
      
    },[signupValues, activeStep]);

    const handleNext = () => {
      if(signupValueErrors.length == 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
      
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    const getFormTitle = () => {
      if(signupType === 'company') {
        return `Create a company account on ${brand.name}`;
      }
      return `Create an expert account on ${brand.name}`;
    };

    if(signupType === '') {
      return (
            <LandingLayout>
                <Box sx={{ width: { xs: 0.9, md: 0.6 }, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', color: 'white' }}>
                <Typography variant="h4">Create an account</Typography>
                <Typography variant="h6">Select your account type:</Typography>
                <Stack direction="row" sx={{ justifyContent: 'space-evenly', mt: 4}}>
                    {/*<Card sx={{ width: '250px' }}>
                        <CardActionArea onClick={() => setSignupType('company')}>
                            <CardContent><BusinessIcon fontSize="large" /><br /><Typography variant="h5" gutterBottom>Company</Typography> I represent a company looking to create a shortlist of experts on {brand.name}</CardContent>
                        </CardActionArea>
                    </Card>*/}
                    <Card sx={{ width: '250px' }}>
                        <CardActionArea onClick={() => setSignupType('expert')}>
                            <CardContent><PersonIcon fontSize="large" /><br /><Typography variant="h5" gutterBottom>Expert</Typography> I am an expert looking to create my profile on {brand.name}</CardContent>
                        </CardActionArea>
                    </Card>
                </Stack>
                </Box>
            </LandingLayout>);
    }

    return (
      <LandingLayout>
        <Grid container spacing={0} className="signupGridContainer">
          <Grid item xs={0} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            {/* header */}
            <Typography variant="h4" color="white" sx={{ mt: 4, ml: 1, mb: 2, textAlign: 'center' }}>{getFormTitle()}</Typography>
          </Grid>
          <Grid item xs={0} lg={3}></Grid>
          <Grid item xs={0} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
          <Paper elevation={1} sx={{ m: { xs: 1, md: 0 }, p: 4 }}>
            
          {activeStep === steps.length ? (
            <React.Fragment>
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <Typography sx={{ mt: 2, mb: 1 }} variant="h4">Submitting your details, please wait ...</Typography>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ m: 4 }}>{(steps.length > 0 && signupType.length > 0) && getSignupSteps(signupType, brand, signupValues, setSignupValues, signupValueErrors)[activeStep]['content']}</Box>
              
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, display: activeStep===0 ? 'none' : 'inline' }}
                  variant="contained"
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button onClick={handleNext} color="secondary" variant="contained" endIcon={<ArrowForwardIcon />} disabled={Object.keys(signupValues).length === 0 || signupValueErrors.length > 0}>
                  {activeStep === steps.length - 1 ? 'Finish' : `Next: ${steps[activeStep+1].title}`}
                </Button>
              </Box>
            </React.Fragment>
          )}
            <Box sx={{ mt: 3 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((s, index) => {
                const stepProps = {};
                const labelProps = {};
                const label = s.title;

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            </Box>
          </Paper>
          </Grid>
          <Grid item xs={0} lg={3}></Grid>
          <Grid item xs={12} sx={{ textAlign: 'center'}}>
            {/* footer */}
            <Typography variant="caption" color="white">&copy; {brand.name}</Typography>
          </Grid>
        </Grid>
      </LandingLayout>
    );
}
