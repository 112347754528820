import React from 'react';
import {
    Stack,
    Grid,
    Typography,
    Link,
    Divider } from '@mui/material';
import { BrandContext } from '../App';

function Footer() {
    const { brand } = React.useContext(BrandContext);

    return (
        <Grid container direction="row" justifyContent="center" spacing={12}>
            <Grid item xs={12} lg={2}>
            <Stack>
                    <Typography variant="button" color="text.secondary">{brand.name}</Typography>
                    <Divider sx={{ mb: 2 }} />
                    {brand.skills.slice(0, 10).map((skill, index) => (
                        <Link href={`/skill/${skill}`} variant="caption">{`${skill} experts`}</Link>
                    ))}
                </Stack>
            </Grid>
            <Grid item xs={12} lg={2}>
            <Stack>
                    <Typography variant="button" color="text.secondary">{brand.name} for agencies</Typography>
                    <Divider sx={{ mb: 2 }} />
                </Stack>
            </Grid>
            <Grid item xs={12} lg={2}>
                <Stack>
                    <Typography variant="button" color="text.secondary">{brand.name} for experts</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Link href="/signup/expert" variant="caption">Join as an expert</Link>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={2}>
                <Stack>
                    
                    <Typography variant="button" color="text.secondary">
                        About us
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Link href="/info/about-us" variant="caption">About us</Link>
                    <Link href="#root" variant="caption" sx={{ mt: 4, textAlign: 'right' }}>Back to top</Link>
                    
                    <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'right' }}>
                        &copy; {brand.name} 2023
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default Footer;
