import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, Chip, Typography, Autocomplete, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { BrandContext } from '../App';
import { createFilterOptions } from '@mui/material/Autocomplete';
import countryList from 'react-select-country-list'

function range(start, end) {
    if(start === end) return [start];
    return [start, ...range(start + 1, end)];
}

function findCurrencyByCode(code) {
    currencies.forEach((ob) => {
        if(ob['code'] === code) {
            console.log(ob);
            return ob;
        }
    });
    return null;
}

const currencies = [
    {
        'code': 'EUR',
        'symbol': '€',
        'name': 'euros'
    },
    {
        'code': 'USD',
        'symbol': '$',
        'name': 'US dollars'
    },
    ,
    {
        'code': 'GBP',
        'symbol': '£',
        'name': 'pounds sterling'
    },
];

const industries = [
    "3D Printing",
    "Artificial Intelligence",
    "Autonomous Vehicle Technology",
    "Biotechnology",
    "Blockchain Technology",
    "Cloud Computing Services",
    "Cybersecurity",
    "Data Analytics",
    "E-commerce Platforms",
    "Fintech (Financial Technology)",
    "Gaming and Interactive Media",
    "HealthTech (Healthcare Technology)",
    "Internet of Things (IoT)",
    "Renewable Energy Technologies",
    "Robotics",
    "Software Development",
    "Aerospace Technology",
    "Virtual Reality and Augmented Reality",
    "Wearable Technology",
    "Web Design",
    "------",
    "Agriculture and Farming",
    "Automotive Manufacturing",
    "Banking and Finance",
    "Construction and Engineering",
    "Consumer Goods and Retail",
    "Education and Training",
    "Energy and Utilities",
    "Fashion and Apparel",
    "Food and Beverage",
    "Healthcare and Pharmaceuticals",
    "Hospitality and Tourism",
    "Information Technology",
    "Insurance",
    "Legal Services",
    "Media and Entertainment",
    "Mining and Metals",
    "Real Estate",
    "Telecommunications",
    "Transportation and Logistics",
    "Waste Management and Environmental Services",
];


export function getSignupSteps(flowType, brand, signupValues={}, setSignupValues=null, signupValueErrors=[]) {

    const autocompleteFilterOptions = createFilterOptions({
        limit: 5
        });
    
    const valueChangeHandler = e => {
        setSignupValues({...signupValues, [e.target.name]: e.target.value || ''});
        }
    
    const skillValueChangeHandler = (e, v) => {
        setSignupValues({...signupValues, ['skills']: v || []});
        }
    
    const signupSteps = {
        'expert': [
            {
                'title': 'Your details',
                'content': (
                    <React.Fragment>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Your contact details</Typography>
                        <Box sx={{ maxWidth: 600 }}>
                            <TextField
                                required
                                name="first_name"
                                label="First name"
                                sx={{ m: 1 }}
                                onChange={valueChangeHandler}
                                value={signupValues["first_name"] || ''}
                                error={Object.keys(signupValues).includes("first_name") && signupValueErrors.includes("first_name")}
                                />
                            <TextField
                                required
                                name="last_name"
                                label="Last name"
                                sx={{ m: 1 }}
                                onChange={valueChangeHandler}
                                value={signupValues["last_name"] || ''}
                                error={Object.keys(signupValues).includes("last_name") && signupValueErrors.includes("last_name")}
                                />
                        </Box>
                        <Box sx={{ maxWidth: 600 }}>
                            <TextField
                                required
                                name="email"
                                label="E-mail"
                                sx={{ m: 1 }}
                                onChange={valueChangeHandler}
                                value={signupValues["email"] || ''}
                                error={Object.keys(signupValues).includes("email") && signupValueErrors.includes("email")}
                                fullWidth
                                />
                        </Box>
                        <Divider variant="middle" sx={{ m: 3 }}/>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Career information</Typography>
                        <Box sx={{ maxWidth: 600 }}>
                            <FormControl fullWidth sx={{ m: 1 }} required>
                                <InputLabel id="role-label">Role title</InputLabel>
                                <Select label="Role title" labelId="role-label" name="roletitle" onChange={valueChangeHandler} value={signupValues["roletitle"] || ''}>
                                    {brand.roletitles.map((title, index) => (
                                        <MenuItem key={index} value={title}>{title}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>No exact match? Select the role title closest to your desired role.</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box sx={{ maxWidth: 300 }}>
                            <FormControl fullWidth sx={{ m: 1 }} required>
                                <InputLabel id="careerstart-label">Professional career start year</InputLabel>
                                <Select label="Professional career start year" labelId="careerstart-label" name="career_start" onChange={valueChangeHandler} value={signupValues["career_start"] || ''}>
                                    <MenuItem value="custom">My career started before 1990</MenuItem>
                                    {range(1990, 2023).map((y, index) => (
                                        <MenuItem key={index} value={y}>{y}</MenuItem>
                                    ))}
                                        <MenuItem value={2024}>No professional experience</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </React.Fragment>)
            },
            {
                'title': 'Skills',
                'content': (
                    <React.Fragment>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Your professional skills</Typography>
                        <Typography variant="body1" sx={{ mb: 2}}>Please select the skills and technologies you have experience in.</Typography>
                        <Autocomplete
                            multiple
                            filterOptions={autocompleteFilterOptions}
                            value={signupValues["skills"] || []}
                            onChange={skillValueChangeHandler}
                            
                            options={brand.skills}
                            
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Skills"
                                    placeholder="Type to search for a skill ..."
                                    name="skills"
                                    required
                                    InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null
                                    }}
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                            fullWidth
                            />
                        <Divider variant="middle" sx={{ m: 3 }}/>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Certifications and Qualifications</Typography>
                        <Typography variant="body1" sx={{ mb: 2}}>If you have any recognized professional certifications or qualifications, please select them here.</Typography>
                        <Autocomplete
                            multiple
                            filterOptions={autocompleteFilterOptions}
                            id="certifications"
                            options={brand.certificates}
                            required
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Certifications"
                                    placeholder="Type to search for a certification ..."
                                    InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null
                                    }}
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                            fullWidth
                            />
                    </React.Fragment>)
            },
            {
                'title': 'Get paid',
                'content': (
                    <React.Fragment>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Your country of residence</Typography>
                        <FormControl sx={{ m: 1, width: 300 }} required>
                            <InputLabel id="country-label">Country</InputLabel>
                            <Select
                                id="country"
                                labelId="country-label"
                                label="Country"
                                name="country"
                                value={signupValues["country"] || ''}
                                onChange={valueChangeHandler}
                            >
                                {countryList().getLabels().map((c, index) => (
                                    <MenuItem key={index} value={c}>{c}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Divider variant="middle" sx={{ m: 3 }}/>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Preferred payout currency</Typography>
                        <Typography variant="body1" sx={{ mb: 2}}>{brand.name} supports multiple payout currencies. Please select your preferred currency.</Typography>
                        <FormControl sx={{ m: 1, width: 300 }} required>
                            <InputLabel id="currency-label">Preferred currency</InputLabel>
                            <Select
                                id="currency"
                                labelId="currency-label"
                                label="Preferred currency"
                                name="fee_currency"
                                value={signupValues["fee_currency"] || ''}
                                onChange={valueChangeHandler}
                            >
                                {currencies.map((c, index) => (
                                    <MenuItem key={index} value={c.code}>{c.symbol} {c.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Divider variant="middle" sx={{ m: 3 }}/>
                        {signupValues["fee_currency"] && (<>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Your fee</Typography>
                        <Typography variant="body1" sx={{ mb: 2}}>Please enter your hourly fee in your preferred payout currency.</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            required
                            label="Hourly fee"
                            name="fee_hourly"
                            value={signupValues["fee_hourly"] || ''}
                            onChange={valueChangeHandler}
                            error={Object.keys(signupValues).includes("fee_hourly") && signupValueErrors.includes("fee_hourly")}
                            />
                        <Typography variant="h5" sx={{ ml: 2 }}>{signupValues["fee_currency"]} / hour</Typography>
                        
                        
                        </Box>
                        </>)}
                            
                    </React.Fragment>)
            },
        ],
        'company': [
            {
                'title': 'Company information',
                'content': (
                    <React.Fragment>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Your details</Typography>
                        <Box sx={{ maxWidth: 600 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label="First name"
                                sx={{ m: 1 }}
                                />
                            <TextField
                                required
                                id="outlined-required"
                                label="Last name"
                                sx={{ m: 1 }}
                                />
                        </Box>
                        
                        <Box sx={{ maxWidth: 600 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label="E-mail"
                                sx={{ m: 1 }}
                                fullWidth
                                />
                        </Box>
                        <Divider variant="middle" sx={{ m: 3 }}/>
                        <Typography variant="h5" sx={{ mt: 4, mb: 2}}>Company details</Typography>
                        <Box sx={{ display: 'flex'}}>
                        <TextField
                                required
                                id="outlined-required"
                                label="Company name"
                                sx={{ m: 1 }}
                                />
                        <FormControl required sx={{ width: 300 }}>
                            <InputLabel id="industry-label">Industry</InputLabel>
                            <Select id="industry" labelId="industry-label" label="Industry" sx={{ m: 1 }}>
                                {industries.map((c, index) => (
                                    <MenuItem key={index} value={c}>{c}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl required sx={{ width: 300 }}>
                            <InputLabel id="size-label">Number of employees</InputLabel>
                            <Select id="size" labelId="size-label" label="Number of employees" sx={{ m: 1 }}>
                                <MenuItem value="1-10">1-10 employees</MenuItem>
                                <MenuItem value="11-30">11-30 employees</MenuItem>
                                <MenuItem value="31-100">31-100 employees</MenuItem>
                                <MenuItem value="101-500">101-500 employees</MenuItem>
                                <MenuItem value="500-plus">Over 500 employees</MenuItem>
                            </Select>
                        </FormControl>
                        </Box>
                    </React.Fragment>)
            },
            {
                'title': 'Required expert profile',
                'content': (<React.Fragment></React.Fragment>),
            },
        ]
    };

    return signupSteps[flowType];
}

