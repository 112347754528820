import * as React from "react";
import Cookies from 'js-cookie';
import {
    createBrowserRouter,
    RouterProvider,
    Routes,
    Route,
    Navigate,
    useLocation,
  } from "react-router-dom";
import LandingPage from './LandingPage';
import SearchResultsPage from './SearchResultsPage';
import PersonDetailPage from './PersonDetailPage';
import Dashboard from './components/Dashboard';
import DashboardHome from "./components/dashboard/DashboardHome";
import Signup from "./pages/Signup";
import LoginPage from "./pages/Login";
import ResetPasswordPage from "./pages/ResetPassword";
import LogoutPage from "./pages/Logout";
import { Info } from "./pages/Info";
import { LanderPage } from "./pages/Lander";

import { Error404 } from "./pages/Error404";

import './App.css';
import { Stack, CircularProgress } from '@mui/material';
import DashboardProfile from "./components/dashboard/DashboardProfile";
import DashboardMarketing from "./components/dashboard/DashboardMarketing";


export const BrandContext = React.createContext(null);

function App() {
    const [brand, setBrand] = React.useState(null);
    const [authToken, setAuthToken] = React.useState('');
    const [loggedInUser, setLoggedInUser] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [matchedListings, setMatchedListings] = React.useState([]);

    var api_base_url;

    React.useEffect(() => {
        // fetch the authentication token from a cookie
        const token = Cookies.get('token');
        if(token && token.length > 0) {
            setAuthToken(token);
        }
    },[]);

    React.useEffect(() => {
        if(!brand || authToken.length < 1) {
            return;
        }

        // fetch logged in user
        fetch(`${brand.api_base_url}/brand/${brand.id}/user`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                if(Object.keys(data).includes('email')) {
                    // success
                    setLoggedInUser(data);

                } else {
                    // failed, log out
                    setLoggedInUser({});
                    saveAuthToken('');
                }
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
        
    },[authToken, brand]);

    React.useEffect(() => {
        if(brand && brand.has('api_base_url')) {
            api_base_url = brand.api_base_url;
        } else {
            if(window.location.hostname.indexOf('.') > 0) {
                if(window.location.hostname.indexOf('www') > -1) {
                    api_base_url = `https://${window.location.hostname.replace("www", "api")}/v1`;
                } else {
                    api_base_url = `https://api.${window.location.hostname}/v1`;
                }
            } else {
                api_base_url = 'http://localhost:8000/v1';
            }
        }
        fetch(`${api_base_url}/brand?fqdn=${window.location.hostname}`, {
        })
            .then((response) => response.json())
            .then((data) => {
                data.api_base_url = api_base_url;
                setBrand(data);
                document.title = data.landing_page_title;
                setLoading(false);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
        
    },[]);

    function saveAuthToken(token) {
        Cookies.set('token', token, { expires: 7, secure: true });
        setAuthToken(token);
        setLoggedInUser({});
    }

    if (loading===true)
    {
        return (
            <>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: 1, height: "100vh" }}
                    >
                    <CircularProgress />
                </Stack>
            </>
        )
    } else {
        const router = createBrowserRouter([
            {
                path: "/",
                element: <LandingPage />,
            },
            {
                path: "skill/:skill",
                element: <SearchResultsPage matchedListings={matchedListings} setMatchedListings={setMatchedListings} />,
            },
            {
                path: "/profile/:id",
                element: <PersonDetailPage matchedListings={matchedListings} />,
            },
            {
                path: "/profile/:id/:slug",
                element: <PersonDetailPage matchedListings={matchedListings} />,
            },
            {
                path: "/engineer/:id",
                element: <PersonDetailPage matchedListings={matchedListings} />,
            },
            {
                path: "/info/:pagename",
                element: <Info />,
            },
            {
                path: "/lander/:pagename",
                element: <LanderPage />,
            },
            {
                path: "/404",
                element: <Error404 />,
            },
            {
                path: "/dashboard/",
                element: <Dashboard />,
                children: [
                    {
                        path: "home",
                        element: <DashboardHome />,
                    },
                    {
                        path: "profile",
                        element: <DashboardProfile />,
                    },
                    {
                        path: "marketing",
                        element: <DashboardMarketing />,
                    },
                ],
            },
            {
                path: "/signup",
                element: <Signup />,
            },
            {
                path: "/signup/:signupFlowName",
                element: <Signup />,
            },
            {
                path: "/login",
                element: <LoginPage />,
            },
            {
                path: "/reset_password",
                element: <ResetPasswordPage />,
            },
            {
                path: "/logout",
                element: <LogoutPage />,
            },
            ]);
    
        return (
        <React.StrictMode>
            <BrandContext.Provider value={{ brand: brand, authToken: authToken, saveAuthToken: saveAuthToken, user: loggedInUser }}>
                <RouterProvider router={router} />
            </BrandContext.Provider>
        </React.StrictMode>
        );
    }
}

export default App;
