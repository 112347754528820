import React from 'react';
import { Box, Button, Typography, Paper, Stack } from '@mui/material';
import { BrandContext } from '../../App';
import heropic1 from '../../assets/man_with_paper.png'

export default function DashboardMarketing() {
    const { brand, user } = React.useContext(BrandContext);
    
    React.useEffect(() => {
        document.title = `Market Your Skills - ${user.first_name} - ${brand.name}`;
    }, [user]);
    
    return (
        <React.Fragment>
            <Typography variant="h4">Market Your Skills</Typography>
            <Paper sx={{ m: 3, p: 2 }}>
                <Stack sx={{ textAlign: 'center'}}>
                    <Box component="img" src={heropic1} sx={{ width: 0.4, maxWidth: '400px', margin: 'auto' }} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h6">LinkedIn Profile</Typography>
                        <Typography variant="body1" sx={{ mt: 2 }}>Integrate {brand.name} with your LinkedIn profile to get more job offers.</Typography>
                        <Button color="secondary" variant="contained" sx={{ mt: 3 }}>Integrate</Button>
                    </Box>
                </Stack>
                
            </Paper>
        </React.Fragment>
    );
}