import React, { useState, useEffect } from 'react';
import { Navigate, Link as RouterLink } from "react-router-dom";
import './LandingPage.css';
import {
    Container,
    Box,
    Stack,
    Autocomplete,
    TextField,
    InputAdornment, 
    Typography,
    Link, 
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    Card,
    CardActionArea,
    CardMedia,
    Chip,
    CardContent,
    Tooltip,

 } from '@mui/material';

import { createFilterOptions } from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';


import { BrandContext } from './App';
import TextTransition, { presets } from 'react-text-transition';

import landingbg from './assets/landingbg.png'
import slugify from 'react-slugify';

import VerifiedIcon from '@mui/icons-material/Verified';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { TopNav } from './components/TopNav';

function LandingPage() {
    const { brand } = React.useContext(BrandContext);
    const [skill, setSkill] = useState('');
    const [index, setIndex] = React.useState(0);
    const [featuredListings, setFeaturedListings] = useState([]);
    
    const autocompleteFilterOptions = createFilterOptions({
        limit: 5
        });
        
    useEffect(() => {
        const intervalId = setInterval(
          () => setIndex((index) => index + 1),
          2000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
      }, []);
    
    useEffect(() => {
        document.title = brand.landing_page_title;
      }, [brand]);

    useEffect(() => {
        fetch(`${brand.api_base_url}/brand/${brand.id}/search_listings?page=1&skill=Terraform&require_profile_picture=true&featured=true`, {
        })
            .then((response) => response.json())
            .then((data) => {
                setFeaturedListings(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
      }, []);

    function onSearch(event, values) {
        setSkill(values);
    }

    

    function getAvailabilityStr(d) {
        var avail_str = 'Available in 1+ month';
    
        const date_available = new Date(d);
        const date_now = new Date();
        const days_til_available = Math.round((date_available.getTime() - date_now.getTime()) / (1000 * 60 * 60 * 24));
        if(days_til_available <= 1) { return [days_til_available, 'Available immediately']; }
        if(days_til_available <= 7) { return [days_til_available, 'Available in 1 week']; }
        if(days_til_available <= 30) { return [days_til_available, 'Available in 2-4 weeks']; }
        return [days_til_available, avail_str];
    }

    return (
        <Box
            sx={{ width: 1,
                background: `url(${landingbg})`
            }}
            >
                {skill && (
                    <Navigate to={`/skill/${skill}`} replace={true} />
                )}

                <Grid container>
                    <Grid item xs={12}>
                        <TopNav />
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={5} sx={{ p: 1 }}>
                        <Box>
                        <Typography variant="h4" color="white" sx={{ mt: 10, mb: 2 }}>
                            Find and hire the best <TextTransition inline={true} className="landing-text-carousel-highlight" springConfig={presets.standard}>{`${brand.roletitles[index % brand.roletitles.length]}s`}</TextTransition>
                        </Typography>
                        <Typography variant="h6" color="white" sx={{ mb: 8, fontWeight: 'normal', width: 0.8 }}>
                            Build your shortlist from 2,000+ professionals, track their availability, and hire them with a single click.
                        </Typography>
                        <Box sx={{ backgroundColor: '#fefefe', borderRadius: '10px', width: 1, p: 2, mt: 2, mb: 2, boxShadow: '3px 3px 3px #666666' }}>
                        <Autocomplete
                            filterOptions={autocompleteFilterOptions}
                            sx={{ m: 2, mb: 0}}
                            id="search-ac"
                            options={brand.skills.map((option) => option)}
                            onChange={onSearch}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Start by searching for a skill"
                                InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                                }}
                            />
                            )}
                        />
                        <Typography variant="caption" sx={{ ml: 3 }}>
                            Try:&nbsp;
                            {brand.skills.slice(0, 3).map((skill, index) => (
                                <Box sx={{ display: 'inline'}} key={index}>
                                    <Link href={`/skill/${skill}`}>{`${skill}`}</Link>
                                    {`${index===2 ? '' : ', '}`}
                                </Box>
                            ))}
                        </Typography>
                        </Box>
                        </Box>
                    </Grid>
                    
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={11} sx={{ mt: 10, p: 1, pl: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'left' }}>
                        <Typography variant="h6" color="white" sx={{ fontWeight: 'normal', mr: 2 }}>Featured on {brand.name}:</Typography>
                        {featuredListings.results && featuredListings.results.slice(0,3).map((person, index) => (
                        <Card key={index} sx={{ m: 2, bgcolor: '#fafafa', width: 250 }}>
                            <CardActionArea component={RouterLink} to={`/profile/${person.id}/${slugify(person.profile.user.display_name+' '+person.title)}`}>
                                <Stack direction='column'>
                                <CardMedia
                                    sx={{ position: 'relative',
                                    width: '1',
                                    height: '250px',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center' }}
                                    component="div"
                                    className="searchProfilePicture"
                                    image={person.profile.profile_picture}
                                >
                                    <Chip color={getAvailabilityStr(person.profile.available_date)[0] < 30 ? 'primary' : 'warning'} icon={getAvailabilityStr(person.profile.available_date)[0] < 30 ? <EventAvailableIcon /> : <DateRangeIcon />} size="small" label={getAvailabilityStr(person.profile.available_date)[1]} sx={{ pl: 0.5, m: 1 }} variant='filled' /> 
                                    <Typography sx={{ m: 1, color: 'primary.contrastText', position: 'absolute', left: 10, bottom: 10 }} variant="h5">{person.profile.user.display_name}</Typography>
                                    <Chip size="small" color="secondary" label={`£${person.profile.hourly_rate} / h`} sx={{ position: 'absolute', right: 10, bottom: -10 }} variant='filled' />
                                </CardMedia>

                                <CardContent>
                                    <Typography variant="h6" color="text.primary" sx={{ display: 'block'}}>
                                        {person.title}
                                    </Typography>
                                    <Typography variant="overline" color="text.secondary">
                                        {person.profile.career_years}+ years of experience
                                    </Typography>
                                    <Box sx={{ mt: 0.5 }}>
                                        {person.profile.certifications.length > 0 ? (person.profile.certifications.slice(0, 1).map((cert, index) => (
                                            <Tooltip key={index} title={`${cert} - Verified by ${brand.name}`} arrow>
                                                <Chip icon={<VerifiedIcon />} color="success" className="goldBadge" size="small" label={cert} sx={{ mr: 1, mb: 1 }} variant='filled' />
                                            </Tooltip>
                                        ))) : (
                                            <Chip label="7-Day Money-Back Guarantee" color="secondary" size="small" sx={{ mr: 1, mb: 1 }} variant='filled' />
                                        )}
                                    </Box>
                                    <Box sx={{ mt: 0.5 }}>
                                        {person.profile.skills.slice(0, 3).map((pskill, index) => (
                                            <Chip key={index} label={pskill} color="primary" size="small" sx={{ mr: 1, mb: 1 }} variant='filled' />
                                        ))}
                                    </Box>
                                </CardContent>
                                </Stack>
                            </CardActionArea>
                        </Card>
                        ))}
                    </Grid>
                </Grid>
                
        </Box>
    );
}

export default LandingPage;