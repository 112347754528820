import * as React from 'react';
import { Link as RouterLink, useParams, Navigate } from "react-router-dom";
import { Typography, Box, Link, Button, Paper } from '@mui/material';
import { BrandContext } from '../App';
import landingbg from '../assets/landingbg.png'
import { LandingLayout } from '../components/LandingLayout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function Info(props) {
    const { brand } = React.useContext(BrandContext);
    const { pagename } = useParams();

    const content = {
        'how-does-it-work': (
            <React.Fragment>
                <Typography variant="h3" sx={{ mb: 3 }}>How does it work?</Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>Experts create their profiles in {brand.name} to showcase their skills.</Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>Companies can shortlist experts, and track their availability in real time.</Typography>
                <Typography variant="body1">It's free to <RouterLink to="/signup">create an account</RouterLink>, so get started right away!</Typography>
            </React.Fragment>
        ),
        'about-us': (
            <React.Fragment>
                <Typography variant="h3" sx={{ mb: 3 }}>About us</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>{brand.name} is a global marketplace for companies to find the best experts:</Typography>
                {brand.skills.slice(0, 20).map((skill, index) => (
                        <>
                        <li><Link href={`/skill/${skill}`} color="inherit">{`${skill}`}</Link></li>
                        </>
                    ))}
            </React.Fragment>
        ),
        'signup-completed': (
            <React.Fragment>
                <Typography variant="h4" sx={{ mb: 3 }}>Signup completed</Typography>
                <Paper sx={{ p: 3 }}>
                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                        <CheckCircleOutlineIcon color="primary" sx={{ fontSize: '8rem' }} />
                        <Typography sx={{ mt: 2, mb: 1 }} variant="h4">Please verify your e-mail</Typography>
                        <Typography sx={{ mt: 2, mb: 2 }} variant="body1">We have sent you an e-mail with instructions to create your password and log in.</Typography>
                        <RouterLink to="/"><Button color="primary" variant="contained">Return to homepage</Button></RouterLink>
                    </Box>
                </Paper>
            </React.Fragment>
        ),
    };

    return (
        <LandingLayout>
            <Box sx={{ width: {xs: 0.9, md: 0.6 }, m: 1, mt: 5, marginLeft: 'auto', marginRight: 'auto', color: 'white'}}>
                {content[pagename] || <Navigate to="/404" replace={true} />}
            </Box>
        </LandingLayout>
    );
}
