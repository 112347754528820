import React from 'react';
import { Alert, Box, Button, Divider, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { BrandContext } from '../../App';

export default function DashboardHome() {
    const { brand, user } = React.useContext(BrandContext);
    
    React.useEffect(() => {
        document.title = `${user.first_name}'s Dashboard - ${brand.name}`;
    }, [user]);
    
    return (
        <React.Fragment>
            <Typography variant="h4">Hello {user.first_name}!</Typography>
        </React.Fragment>
    );
}