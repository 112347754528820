import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LogoutIcon from '@mui/icons-material/Logout';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { BrandContext } from '../../App';

export function MainDashboardMenu() {
  const { user } = React.useContext(BrandContext);

  return (
  <React.Fragment>
    {user.profile && (
      <>
      <ListSubheader component="div" inset>
      Expert
    </ListSubheader>
    <ListItemButton component={RouterLink} to="/dashboard/home">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/profile">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Profile" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/marketing">
      <ListItemIcon>
        <AutoFixHighIcon />
      </ListItemIcon>
      <ListItemText primary="Market Your Skills" />
    </ListItemButton>
    {/*<ListItemButton component={RouterLink} to="/dashboard/engagements">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Engagements" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/settings">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>*/}
    </>
    )}
    {user.company && (
      <>
      <Divider />
      <ListSubheader component="div" inset>
      {user.company.name}
    </ListSubheader>
    <ListItemButton component={RouterLink} to="/dashboard/lists">
      <ListItemIcon>
        <EventSeatIcon />
      </ListItemIcon>
      <ListItemText primary="Lists" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/engagements">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Engagements" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/settings">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
    </>
    )}
    
  </React.Fragment>
  );
}

export function SecondaryDashboardMenu() {
  return (
  <React.Fragment>
{/*    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
*/}
  <ListItemButton component={RouterLink} to="/logout">
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Log out" />
    </ListItemButton>
  </React.Fragment>
);
}