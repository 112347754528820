import React, { useState, useEffect } from 'react';
import Footer from './components/Footer'
import { TopNav } from './components/TopNav';
import MessagePopup from './components/MessagePopup'
import { CircularProgress, Box,
    Stack,
    Paper,
    Grid,
    Typography,
    InputLabel,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Autocomplete,
    TextField,
    Tooltip,
    Card,
    CardMedia,
    CardActionArea,
    CardContent,
    Chip,
    InputAdornment,
    Slider,
    Link,
    Divider, 
    Button,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemButton,
    CardHeader,
    Modal,
    AppBar,
    Toolbar,
    IconButton} from '@mui/material';
import { BrandContext } from './App';

import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';

import VerifiedIcon from '@mui/icons-material/Verified';
import WorkIcon from '@mui/icons-material/Work';
import PlaceIcon from '@mui/icons-material/Place';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PaymentIcon from '@mui/icons-material/Payment';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import MessageIcon from '@mui/icons-material/Message';
import FactoryIcon from '@mui/icons-material/Factory';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircle from '@mui/icons-material/AccountCircle';
import slugify from 'react-slugify';

import ScrollToTop from "./components/ScrollToTop";

function PersonDetail({ matchedListings, setMatchedListings }) {
    const { brand } = React.useContext(BrandContext);
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const { id } = useParams();
    const [selectedProfile, setSelectedProfile] = useState(id);
    const [selectedProfileData, setSelectedProfileData] = useState({});
    const [loading, setLoading] = React.useState(true);

    const navigate = useNavigate();

    var displayProfile = {};
    
    function getProjectDurationInMonths(start, end) {
        const date_start = new Date(start);
        const date_end = new Date(end);
        return Math.round((date_end.getTime() - date_start.getTime()) / (1000 * 60 * 60 * 24 * 30));
    }

    function getAvailabilityStr(d) {
        var avail_str = 'in 1+ month';
    
        const date_available = new Date(d);
        const date_now = new Date();
        const days_til_available = Math.round((date_available.getTime() - date_now.getTime()) / (1000 * 60 * 60 * 24));
        if(days_til_available <= 1) { return [days_til_available, 'immediately']; }
        if(days_til_available <= 7) { return [days_til_available, 'in 1 week']; }
        if(days_til_available <= 30) { return [days_til_available, 'in 2-4 weeks']; }
        return [days_til_available, avail_str];
    }

    useEffect(() => {
        setLoading(true);
        fetch(`${brand.api_base_url}/brand/${brand.id}/listing/${selectedProfile}`, {
        })
            .then((response) => response.json())
            .then((data) => {
                setSelectedProfileData(data);
                document.title = `${data.profile.user.display_name} (${data.title}): available on ${brand.name}`;
                setLoading(false);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }, [selectedProfile]);

    const handleProfileSelection = (id, slug) => {
        setSelectedProfile(id);
        navigate(`/profile/${id}/${slug}`, { replace: true });
      };
    
    function handleAppBarMenu(e) {
        return;
    }
    return (
        <React.Fragment>
            <ScrollToTop />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TopNav />
                </Grid>
                <Grid item xs={2} display={{ xs: 'none', md: 'initial' }}>
                    
                    {matchedListings.count > 0 ? (<Paper elevation={1} sx={{ padding: 1, textAlign: 'center', bgcolor: '#fafafa' }}>
                        <Button onClick={() => navigate(-1)} variant='outlined' startIcon=<ArrowBackIcon />> Back to search</Button>
                        {/*
                        <List>
                            {matchedListings.map((person, index) => (
                            <ListItem disablePadding alignItems="flex-start" key={index}>
                                <ListItemButton selected={person.profile.id==selectedProfile} onClick={() => handleProfileSelection(person.id, slugify(person.profile.user.display_name+' '+person.title))}>
                                    <ListItemAvatar>
                                        <Avatar alt={person.profile.user.display_name} src={person.profile.profile_picture} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={person.profile.user.display_name}
                                        secondary={
                                            <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {person.title}
                                            </Typography>
                                            {` — £${person.profile.hourly_rate} p/h`}
                                            </React.Fragment>
                                        }
                                        />
                                </ListItemButton>
                            </ListItem>
                            ))}
                        </List> */}
                    </Paper>) : (
                        <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
                            <Typography component="h1" variant="h5" color="text.primary" sx={{ pb: 2 }}>{brand.landing_page_title}</Typography>
                            <Button onClick={() => navigate('/')} variant='outlined' sx={{ m: 2 }} endIcon={<SearchIcon />}>Search</Button>
                            <List disablePadding>
                                {brand.skills.slice(0, 10).map((skill, index) => (
                                <ListItem key={index}>
                                    <Link href={`/skill/${skill}`}><ListItemText primary={`${skill}`}></ListItemText></Link>
                                </ListItem>
                                ))}
                            </List>
                        </Paper>
                    )}
                </Grid>
                <Grid item sx={{ p: 2 }} xs>
                    <Paper elevation={1} sx={{ p: 2 }}>
                    {loading ? (<CircularProgress />) : (
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <MessagePopup open={showMessagePopup} setOpen={setShowMessagePopup} selectedProfileData={selectedProfileData} />
                                <Grid container>
                                    <Grid item xs={12} xl={2}>
                                        <Avatar sx={{ width: 150, height: 150 }} src={selectedProfileData.profile.profile_picture} alt={selectedProfileData.profile.user.display_name} />
                                    </Grid>
                                    <Grid item sx={{ pt: 4 }}>
                                        <Typography component="h1" variant="h4" color="text.primary">{selectedProfileData.profile.user.display_name}</Typography>
                                        <Stack direction='row' sx={{ mt: 1 }}>
                                            <Box sx={{ mr: 2 }}><Typography variant="body2" color="text.secondary"><WorkIcon fontSize='inherit' /> {selectedProfileData.title}</Typography></Box>
                                            <Box sx={{ mr: 2 }}><Typography variant="body2" color="text.secondary"><PlaceIcon fontSize='inherit' /> {selectedProfileData.profile.location}</Typography></Box>
                                        </Stack>                            
                                        <Box>
                                            <Button variant='contained' sx={{ mt: 2 }} endIcon={<MessageIcon />} onClick={() => setShowMessagePopup(true)}>Send a message</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <Typography variant="body1" color="text.primary" sx={{ display: 'inline' }}>{selectedProfileData.profile.user.first_name} is a {selectedProfileData.title} with {selectedProfileData.profile.career_years} years of experience.</Typography>&nbsp;
                                        {selectedProfileData.profile.skills.length > 2 && <Typography variant="body1" color="text.primary" sx={{ display: 'inline' }}>Their skills include {selectedProfileData.profile.skills[0]}, {selectedProfileData.profile.skills[1]}, and {selectedProfileData.profile.skills[2]} among others.&nbsp;</Typography>}
                                        {selectedProfileData.profile.certifications.length > 0 && <Typography variant="body1" color="text.primary" sx={{ display: 'inline' }}>{selectedProfileData.profile.user.first_name} holds the {selectedProfileData.profile.certifications[0]} certification.&nbsp;</Typography>}
                                        <Typography variant="body1" color="text.primary" sx={{ display: 'inline' }}>Based in {selectedProfileData.profile.location}, {selectedProfileData.profile.user.first_name} is available for {selectedProfileData.profile.workmodes.join("/")} work {getAvailabilityStr(selectedProfileData.profile.available_date)[1]}.&nbsp;</Typography>
                                        
                                        {selectedProfileData.profile.skills.length > 0 && <Card sx={{ p: 2, mt: 2, mb: 2 }} elevation={1}>
                                            <Typography component="h3" variant="h6" color="text.secondary" sx={{ mb: 1 }}>Skills & Competencies</Typography>
                                            {selectedProfileData.profile.skills.map((pskill, index) => (
                                                <Chip key={index} label={pskill} color="primary" size="small" sx={{ mr: 1, mb: 1 }} variant='filled' />
                                            ))}
                                        </Card>}

                                        {selectedProfileData.profile.projectreferences.length > 0 && <Box>
                                            <Typography component="h3" variant="h6" color="text.secondary" sx={{ mb: 1 }}>Recent Project Experience</Typography>
                                            {selectedProfileData.profile.projectreferences.map((pref, index) => (
                                                <Card key={index} sx={{ mb: 1 }}>
                                                    <CardContent>
                                                        <Stack direction='row' sx={{ mt: 1 }}>
                                                            <Box sx={{ mr: 3 }}><Typography variant="body2" color="text.secondary"><WorkIcon fontSize='inherit' sx={{ mr: 1 }} />Role: <strong>{pref.role}</strong></Typography></Box>
                                                            <Box sx={{ mr: 3 }}><Typography variant="body2" color="text.secondary"><FactoryIcon fontSize='inherit' sx={{ mr: 1 }} />Industry: <strong>{pref.industry}</strong></Typography></Box>
                                                            <Box><Typography variant="body2" color="text.secondary"><EventAvailableIcon fontSize='inherit' sx={{ mr: 1 }} />Duration: <strong>{pref.duration_months} months</strong></Typography></Box>
                                                        </Stack>     
                                                        <Typography variant="body1" color="text.primary" sx={{ ml: 1, mt: 0.5 }}>{pref.description}</Typography>
                                                    </CardContent>
                                                </Card>
                                            ))}
                                        </Box>}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={3}>
                            <Card sx={{ p: 2, mb: 2 }} elevation={2}>
                                    <List sx={{ textAlign: 'center' }}>
                                        <ListItem>
                                            <ListItemAvatar><EventAvailableIcon /></ListItemAvatar>
                                            <ListItemText primary={`Available ${getAvailabilityStr(selectedProfileData.profile.available_date)[1]}`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar><PaymentIcon /></ListItemAvatar>
                                            <ListItemText primary={`£${selectedProfileData.profile.hourly_rate} per hour All-Inclusive`} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar><WorkspacePremiumIcon /></ListItemAvatar>
                                            <ListItemText primary="7-Day Money-Back Guarantee" />
                                        </ListItem>
                                    </List>
                                    
                                    
                                </Card>
                                {selectedProfileData.profile.certifications.length > 0 && <Card sx={{ p: 2}}>
                                    <Typography component="h3" variant="h6" color="text.secondary">Certifications & Credentials</Typography>
                                    <List>
                                        {selectedProfileData.profile.certifications.map((cert, index) => (
                                        <ListItem key={index}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <VerifiedIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={cert} />
                                        </ListItem>
                                        ))}
                                    </List>
                                    <Typography variant="caption" color="text.secondary">Verified by {brand.name}</Typography>
                                </Card>}
                            </Grid>
                        </Grid>
                    )}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                <Paper elevation={0} sx={{ mt: 16 }}>
                    <Footer />
                </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default PersonDetail;
