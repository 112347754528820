import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, AppBar, Toolbar, Link, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import { BrandContext } from '../App';
import AccountCircle from '@mui/icons-material/AccountCircle';



export function TopNav(props) {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const navigate = useNavigate();

    function handleAppBarMenu(e) {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getLinks(LinkElement=Link) {
        if(user.company) {
            return (
                    <LinkElement color="inherit" onClick={() => navigate(`/dashboard/lists`)} href={`/dashboard/lists`} sx={{ mr: 3 }}>My Benches</LinkElement>
            );
        }

        if(user.profile) {
            return (
                    <LinkElement onClick={() => navigate(`/dashboard/profile`)} href={`/dashboard/profile`} sx={{ mr: 3 }}>{user.first_name} {user.last_name}</LinkElement>
            );
        }
        // Default, not logged in
        return (
            [
                <LinkElement key={1} color="inherit" onClick={() => navigate(`/signup`)} href={`/signup`} sx={{ mr: 3 }}>Create account</LinkElement>,
                <LinkElement key={2} color="inherit" onClick={() => navigate(`/info/how-does-it-work`)} href={`/info/how-does-it-work`} sx={{ mr: 3 }}>How does it work?</LinkElement>,
                <LinkElement key={3} color="inherit" onClick={() => navigate(`/info/about-us`)} href={`/info/about-us`} sx={{ mr: 3 }}>About us</LinkElement>,
                <LinkElement key={4} color="inherit" onClick={() => navigate(`/login`)} href={`/login`} sx={{ mr: 3 }}>Log in</LinkElement>,
            ]
        );
    }

    return (
        <AppBar position="static" elevation={0} sx={{ pt: 2, pb: 2 }}>
            <Toolbar>
                <Box sx={{ width: 1, display: {xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <RouterLink to="/"><Box component="img" src={brand.logo} alt={brand.name} sx={{ width: 0.5 }} /></RouterLink>
                    <Typography variant="caption" color="white" component="div" sx={{ flexGrow: 1, justifyContent: 'flex-end', display: 'flex' }}>
                        {getLinks()}
                    </Typography>
                    <RouterLink to={authToken ? '/dashboard/home' : `/login?next=${window.location.pathname}`}>
                        <IconButton
                            size="large"
                            edge="end">
                            <AccountCircle />
                        </IconButton>
                    </RouterLink>
                </Box>
                <Box sx={{ width: 1, display: {xs: 'flex', md: 'none' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <RouterLink to="/"><Box component="img" src={brand.logo} alt={brand.name} sx={{ width: 0.7 }} /></RouterLink>
                    <IconButton
                        size="large"
                        onClick={handleAppBarMenu}
                        edge="end">
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="login-menu"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        {getLinks(MenuItem)}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

