import React from 'react';
import { Alert, Box, Button, Divider, Modal, Snackbar, TextField, Typography } from '@mui/material';
import { BrandContext } from '../App';

function MessagePopup({ open, setOpen, selectedProfileData }) {
    const { brand } = React.useContext(BrandContext);
    const handleClose = () => setOpen(false);
    const [fromName, setFromName] = React.useState('');
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const handleConfirmationClose = () => setOpenConfirmation(false);
    const [fromEmail, setFromEmail] = React.useState('');
    const [formMessage, setFormMessage] = React.useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    function handleSubmit(e) {
        e.preventDefault();
        
        (async () => {
            const rawResponse = await fetch(`${brand.api_base_url}/brand/${brand.id}/submit_message`, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                recipient: selectedProfileData.profile.user.display_name,
                recipient_id: selectedProfileData.id,
                from_name: fromName,
                from_email: fromEmail,
                message: formMessage
            })
            });
            const content = await rawResponse.json();
          
            console.log(content);
          })();
        setOpen(false);
        setOpenConfirmation(true);
    }

    return (
        <React.Fragment>
        <Snackbar open={openConfirmation} onClose={handleConfirmationClose} autoHideDuration={6000}>
            <Alert severity="success" sx={{ width: '100%' }}>
            Your message has been sent!
            </Alert>
        </Snackbar>
        
        <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Send a message
          </Typography>
          <Box sx={{ mt: 2, alignItems: 'center', textAlign: 'center' }}>
            <form onSubmit={handleSubmit}>
            <TextField variant='outlined' label='Recipient' value={`${selectedProfileData.profile.user.display_name} (${selectedProfileData.title})`} disabled fullWidth sx={{ m: 1 }} />
            <Divider variant="middle" />
            <TextField variant='outlined' onChange={e => setFromName(e.target.value)} label='Your name' required fullWidth sx={{ m: 1 }} />
            <TextField variant='outlined' onChange={e => setFromEmail(e.target.value)} label='Your email' required fullWidth sx={{ m: 1 }} />
            <TextField variant='outlined' onChange={e => setFormMessage(e.target.value)} label='Message' required multiline rows={4} fullWidth sx={{ m: 1 }} />
            <Button variant='outlined' sx={{ mr: 'auto', ml: 'auto' }} type='submit'>Send</Button>
            </form>
          </Box>
        </Box>
      </Modal>
      </React.Fragment>
    );
}

export default MessagePopup;

/*
<div className="messagepopup-container" onClick={closePopup}>
            {showLoginPopup && <LoginPopup setShowPopup={setShowLoginPopup} />}
            <div className="messagepopup" onClick={interceptPopupClick}>
                <img src={logo} alt="DevOpsHire" />
                <h3>Please sign into your account:</h3>
                <button onClick={openLoginPopup}>Sign in</button>
                <hr />
                <h3>Or register and send a message (it's free!):</h3>
                <form>
                    <div className="form-fields">
                        <div className="message-form-field"><label htmlFor="to-name">To</label> <input type="text" id="to-name" value={recipient} disabled /></div>
                        <div className="message-form-field"><label htmlFor="name">Your name</label> <input type="text" id="name" /></div>
                        <div className="message-form-field"><label htmlFor="email">Your email</label> <input type="text" id="email" /></div>
                        <div className="message-form-field"><label htmlFor="message">Message</label> <textarea id="message" /></div>
                    </div>
                    <input type="submit" value="Send message" />
                    <div className="disclaimer">The engineer will reply to your message within 24 hours.<br />We will send you a link to create your DevOpsHire account.<br /><br /><strong><Link onClick={closePopup}>Cancel</Link></strong></div>
                </form>
            </div>
        </div>
*/