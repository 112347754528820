import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Footer from './components/Footer'
import { TopNav } from './components/TopNav';
import { BrandContext } from './App';
import { CircularProgress, Box,
    Stack,
    Paper,
    Grid,
    Typography,
    InputLabel,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Autocomplete,
    TextField,
    Tooltip,
    Card,
    CardMedia,
    CardActionArea,
    CardContent,
    Chip,
    InputAdornment,
    Slider,
    Link,
    Divider, 
    AppBar,
    Button,
    Toolbar, IconButton} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createFilterOptions } from '@mui/material/Autocomplete';
import slugify from 'react-slugify';

import ScrollToTop from "./components/ScrollToTop";

function SearchResultsPage({ matchedListings, setMatchedListings }) {
    
    const [searchSkills, setSearchSkills] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [selectedResultsPage, setSelectedResultsPage] = useState(1);
    const [loading, setLoading] = React.useState(true);
    const { brand } = React.useContext(BrandContext);
    const { skill } = useParams()
    const [orderBy, setOrderBy] = React.useState('algo');
    const [maxBudget, setMaxBudget] = React.useState(-1);
    const [maxAvailability, setMaxAvailability] = React.useState(-1);

    const autocompleteFilterOptions = createFilterOptions({
        limit: 5
        });
    
    function getAvailabilityStr(d) {
        var avail_str = 'Available in 1+ month';
    
        const date_available = new Date(d);
        const date_now = new Date();
        const days_til_available = Math.round((date_available.getTime() - date_now.getTime()) / (1000 * 60 * 60 * 24));
        if(days_til_available <= 1) { return [days_til_available, 'Available immediately']; }
        if(days_til_available <= 7) { return [days_til_available, 'Available in 1 week']; }
        if(days_til_available <= 30) { return [days_til_available, 'Available in 2-4 weeks']; }
        return [days_til_available, avail_str];
    }

    function changeOrderBy(event) {
        setOrderBy(event.target.value);
    }

    function changeMaxBudget(event) {
        setMaxBudget(event.target.value);
    }

    function changeMaxAvailability(event) {
        setMaxAvailability(event.target.value);
    }

    function doSearch() {
        if(searchSkills===null || !searchSkills || searchSkills.length < 1) { return; }

        var skill_query_str = "&skill=" + searchSkills.join("&skill=");
        var additional_filters = "";

        if(maxBudget) { additional_filters += `&rate_lt=${maxBudget}`; }
        if(maxAvailability) { additional_filters += `&availableindays_lt=${maxAvailability}`; }
        if(orderBy) { additional_filters += `&order_by=${orderBy}`; }

        fetch(`${brand.api_base_url}/brand/${brand.id}/search_listings?page=${selectedResultsPage}${skill_query_str}${additional_filters}`, {
        })
            .then((response) => response.json())
            .then((data) => {
                setMatchedListings(data);
                document.title = `${data.count}+ ${searchSkills.join(" and ")} experts available on ${brand.name}`;
                window.scrollTo(0, 0);
                setLoading(false);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });
    }

    useEffect(() => {
        doSearch();

    }, [searchSkills, selectedResultsPage, orderBy, maxAvailability, maxBudget]);

    useEffect(() => {
        if(searchSkills.length < 1) {
            setSearchSkills([skill]);
            doSearch();
        }
    }, []);

    function handleSkillChange(event, values) {
        setSearchSkills(values);
    }

    function handleAppBarMenu(e) {
        return;
    }

    if (loading===true)
    {
        return (
            <>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: 1, height: "100vh" }}
                    >
                    <CircularProgress />
                </Stack>
            </>
        )
    }

    return (
        <React.Fragment>
            <ScrollToTop />
            <Grid container spacing={0}>
            <Grid item xs={12}>
                <TopNav />
            </Grid>
            <Grid item xs={12} md={2}>
                <Paper elevation={1} sx={{ padding: 1, alignContent: 'center', bgcolor: '#fafafa', minWidth: '300' }}>
                    <Stack spacing={2}>
                        <Typography variant="button" color="text.secondary">Filter profiles</Typography>
                        <Autocomplete
                            multiple
                            disableClearable
                            limitTags={2}
                            filterOptions={autocompleteFilterOptions}
                            id="search-skills"
                            options={brand.skills}
                            value={searchSkills}
                            onChange={handleSkillChange}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Skills"
                                    InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null
                                    }}
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                    disabled={tagValue.length==1}
                                  />
                                ))
                              }
                            fullWidth
                            />
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="max-budget-label">Maximum budget</InputLabel>
                            <Select
                            labelId="max-budget-label"
                            id="max-budget"
                            value={maxBudget}
                            label="Maximum budget"
                            onChange={changeMaxBudget}
                            defaultValue="-1"
                            >
                            <MenuItem value="-1">Any</MenuItem>
                            <MenuItem value="201">&lt; £200 per hour</MenuItem>
                            <MenuItem value="151">&lt; £150 per hour</MenuItem>
                            <MenuItem value="100">&lt; £100 per hour</MenuItem>
                            <MenuItem value="51">&lt; £50 per hour</MenuItem>
                            <MenuItem value="11">&lt; £10 per hour</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="max-availability-label">Availability</InputLabel>
                            <Select
                            labelId="max-availability-label"
                            id="max-availability"
                            value={maxAvailability}
                            label="Availability"
                            onChange={changeMaxAvailability}
                            defaultValue="-1"
                            >
                            <MenuItem value="-1">Any</MenuItem>
                            <MenuItem value="7">Within 7 days</MenuItem>
                            <MenuItem value="14">Within 14 days</MenuItem>
                            <MenuItem value="31">Within a month</MenuItem>
                            <MenuItem value="90">Within 3 months</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="order-by-label">Show first</InputLabel>
                            <Select
                            labelId="order-by-label"
                            id="order-by"
                            value={orderBy}
                            label="Show first"
                            onChange={changeOrderBy}
                            defaultValue="algo"
                            >
                            <MenuItem value="algo">Recommended</MenuItem>
                            <MenuItem value="experience">Most experienced</MenuItem>
                            <MenuItem value="availability">Available soonest</MenuItem>
                            <MenuItem value="rate">Lowest rate</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'right' }}>{matchedListings.count} matching profile{matchedListings.count==1 ? '' : 's'}</Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={true}>
                <Paper elevation={0} sx={{ p: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'baseline' }}>
                {matchedListings.count == 0 && (
                        <Typography gutterBottom variant="h5">
                            Sorry, no matches.
                        </Typography>
                    )}
                    {matchedListings.results.map((person, index) => (
                        <Card key={index} sx={{ m: 2, bgcolor: '#fafafa', width: 250 }}>
                            <CardActionArea component={RouterLink} to={`/profile/${person.id}/${slugify(person.profile.user.display_name+' '+person.title)}`}>
                                <Stack direction='column'>
                                <CardMedia
                                    sx={{ position: 'relative',
                                    width: '1',
                                    height: '250px',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center' }}
                                    component="div"
                                    className="searchProfilePicture"
                                    image={person.profile.profile_picture}
                                >
                                    <Chip color={getAvailabilityStr(person.profile.available_date)[0] < 30 ? 'primary' : 'warning'} icon={getAvailabilityStr(person.profile.available_date)[0] < 30 ? <EventAvailableIcon /> : <DateRangeIcon />} size="small" label={getAvailabilityStr(person.profile.available_date)[1]} sx={{ pl: 0.5, m: 1 }} variant='filled' /> 
                                    <Typography sx={{ m: 1, color: 'primary.contrastText', position: 'absolute', left: 10, bottom: 10 }} variant="h5">{person.profile.user.display_name}</Typography>
                                    <Chip size="small" color="secondary" label={`£${person.profile.hourly_rate} / h`} sx={{ position: 'absolute', right: 10, bottom: -10 }} variant='filled' />
                                </CardMedia>

                                <CardContent>
                                    <Typography variant="h6" color="text.primary" sx={{ display: 'block'}}>
                                        {person.title}
                                    </Typography>
                                    <Typography variant="overline" color="text.secondary">
                                        {person.profile.career_years}+ years of experience
                                    </Typography>
                                    <Box sx={{ mt: 0.5 }}>
                                        {person.profile.certifications.length > 0 ? (person.profile.certifications.slice(0, 1).map((cert, index) => (
                                            <Tooltip key={index} title={`${cert} - Verified by ${brand.name}`} arrow>
                                                <Chip icon={<VerifiedIcon />} color="success" className="goldBadge" size="small" label={cert} sx={{ mr: 1, mb: 1 }} variant='filled' />
                                            </Tooltip>
                                        ))) : (
                                            <Chip label="7-Day Money-Back Guarantee" color="secondary" size="small" sx={{ mr: 1, mb: 1 }} variant='filled' />
                                        )}
                                    </Box>
                                    <Box sx={{ mt: 0.5 }}>
                                        {person.profile.skills.map((pskill, index) => (
                                            searchSkills.indexOf(pskill) != -1 && (<Chip key={index} label={pskill} color="primary" size="small" sx={{ mr: 1, mb: 1 }} variant='filled' />)
                                        ))}
                                    </Box>
                                </CardContent>
                                </Stack>
                            </CardActionArea>
                        </Card>
                        ))}
                        
                </Paper> 
                <Box sx={{ m: 2 }}>
                        <Button disabled={matchedListings.previous ? false : true} sx={{ mr: 1 }} onClick={() => setSelectedResultsPage(selectedResultsPage - 1)} variant='outlined' startIcon=<ArrowBackIcon />>Previous page</Button>
                        <Button disabled={matchedListings.next ? false : true}  onClick={() => setSelectedResultsPage(selectedResultsPage + 1)} variant='outlined' startIcon=<ArrowForwardIcon />>Next page</Button>
                </Box>
            </Grid>
            
            <Grid item xs={12}>
                <Paper elevation={0} sx={{ mt: 16 }}>
                    <Footer />
                </Paper>
            </Grid>
        </Grid>
        </React.Fragment>
        
    );
}

export default SearchResultsPage;