import * as React from 'react';
import { Link as RouterLink, useParams, Navigate } from "react-router-dom";
import { Typography, Box } from '@mui/material';
import { BrandContext } from '../App';
import landingbg from '../assets/landingbg.png'
import { LandingLayout } from '../components/LandingLayout';


export function Error404(props) {
    const { brand } = React.useContext(BrandContext);

    return (
        <LandingLayout>
            <Box sx={{ width: {xs: 1, md: 0.6 }, m: 1, mt: 5, marginLeft: 'auto', marginRight: 'auto', color: 'white'}}>
                <Typography variant="h3">Page not found</Typography>
                <Typography variant="body1">Unfortunately the page you were looking for was not found.</Typography>
            </Box>
        </LandingLayout>
    );
}
